import { logEvent } from "firebase/analytics";
import { ShippingInfo, ShippingProgress, StoreOwner } from "../Slice";
import analytics from "../../../shared/api/FirebaseApp";
import { GetDate, GetNow, GetTime } from "../../../utils/GetDateNow";
import { Map, Overlay, ZoomControl } from "pigeon-maps";
import { Layout, Steps } from "antd";
import { ClockCircleOutlined, FlagFilled } from "@ant-design/icons";
import { Fragment } from "react/jsx-runtime";
import iconCar from "../../../assets/images/logo/logo.png";
import iconPhone from "../../../assets/images/icons/phone.png";
import storeIcon from "../../../assets/images/icons/store.png";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getStoresTracking } from "../services/store-tracking";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { StoreContext } from "../../../shared/context/provider";
import LoadingComponent from "./LoadingComponent";

function DetailComponent() {
  const { tracking_number, truck_plate_number } = useParams();
  const [lat] = useState(13.726817);
  const [lng] = useState(100.52927);
  const [latStore, setLatStore] = useState(13.726817);
  const [lngStore, setLngStore] = useState(100.52927);
  const companyCode = process.env.REACT_APP_COMPANY_CODE;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [shippingInfo, setShippingInfo] = useState<ShippingInfo>();
  const storeContext = useContext(StoreContext);

  const { data, error, isLoading, isFetching } = useQuery<StoreOwner, AxiosError>({
    queryKey: ["storeTracking"],
    queryFn: () => getStoresTracking(tracking_number as string, searchParams.get("company") as string),
    refetchInterval: 2 * (1000 * 60),
  });

  if (error && error.response) {
    const httpStatus = error.response.status;
    if (httpStatus === 404) {
      navigate("/error?status=404");
    } else {
      navigate("/error?status=500");
    }
  }

  useEffect(() => {
    const shippingInfoItem: ShippingInfo | undefined = data?.shipping_infos?.find(
      (info) => info.truck_plate_number === truck_plate_number,
    );
    if (shippingInfoItem) {
      setShippingInfo(shippingInfoItem);
      setLatStore(parseFloat(shippingInfoItem.lat as string));
      setLngStore(parseFloat(shippingInfoItem.long as string));
    }

    if (data) {
      storeContext?.setStoreDetail({
        name: data.store_name,
        number: data.store_number,
        displayBack: true,
      });
    }
  }, [data, truck_plate_number]);

  const defaultProps = {
    center: {
      lat: lat,
      lng: lng,
    },
    zoom: 15,
  };

  const updatedDateView = () => {
    const timeNow = GetNow();

    return (
      <>
        อัพเดตล่าสุด <span>{shippingInfo && timeNow}</span>
      </>
    );
  };

  const etaItem = (info: ShippingInfo) => {
    const etaTimeStart = GetDate(info.eta_start);
    const etaTimeEnd = GetTime(info.eta_end);

    return (
      <div className="section-eta">
        <ClockCircleOutlined rev={""} />
        <p data-testid="store-owner-eta">
          {info.eta !== null ? etaTimeStart + " - " + etaTimeEnd : "N/A"}
          {info.status === "waiting" ? "" : " *"}
        </p>
      </div>
    );
  };

  const shippingProgressItem = (stop: string, process: ShippingProgress, info: ShippingInfo) => {
    let status;
    if (process.status === "success") {
      status = "จัดส่งสำเร็จ";
    } else if (process.status === "in-transit" || process.status === "arrived") {
      status = "กำลังจัดส่ง";
    } else if (process.status === "waiting") {
      status = "รอดำเนินการ";
    }

    let title;
    let eta;
    if (companyCode === "TD") {
      if (stop === process.stop) {
        title = (
          <span>
            <FlagFilled rev="" style={{ marginRight: 10, color: "#e50019" }} />
            ร้านค้าของท่าน
          </span>
        );
        eta = etaItem(info);
      } else {
        title = `ร้านค้าลำดับที่ ${process.stop}`;
      }
    } else if (companyCode === "CJ") {
      if (stop === process.stop) {
        title = (
          <span>
            <FlagFilled rev="" style={{ marginRight: 10, color: "#e50019" }} />
            {process.store_number} - {process.store_name}
          </span>
        );
        eta = etaItem(info);
      } else {
        title = `${process.store_number} - ${process.store_name}`;
      }
    }

    return {
      title: title,
      description: (
        <>
          {status}
          {eta}
        </>
      ),
    };
  };

  const shippingProgressView = (info: ShippingInfo) => {
    let currentIndex = 0;
    if (info.progress) {
      for (let i = 0; i < info.progress.length; i++) {
        if (info.progress[i].status === "in-transit" || info.progress[i].status === "arrived") {
          currentIndex = i;
          break;
        }
      }

      const items = info.progress?.map((process: ShippingProgress) => {
        return shippingProgressItem(info.stop, process, info);
      });
      return <Steps className="step-custom" direction="vertical" current={currentIndex} items={items} />;
    }
    return <></>;
  };

  const waitingInfoView = (info: ShippingInfo) => {
    return (
      info && (
        <div className="section-transit-detail">
          <section className="section-element section-status">
            <div className="left">
              <h3>สถานะการจัดส่งสินค้า</h3>
            </div>
            <div className="right">
              <span className="wating">รอดำเนินการ</span>
            </div>
          </section>
          <section className="section-element section-detail">
            <div className="left">
              <h3>รายละเอียดรถขนส่ง</h3>
              <p>ประเภทรถ : {info.truck_type}</p>
              <p>ทะเบียน : {info.truck_plate_number}</p>
            </div>
            {driverMobileNumberView(info.driver_mobile_number, storeContext?.storeDetail.number)}
          </section>
          <section>{shippingProgressView(info)}</section>
        </div>
      )
    );
  };

  const inTransitInfoView = (info: ShippingInfo) => {
    return (
      info && (
        <div className="section-transit-detail">
          <section className="section-element section-status">
            <div className="left">
              <h3>สถานะการจัดส่งสินค้า</h3>
            </div>
            <div className="right">
              <span className="processing">กำลังจัดส่ง</span>
            </div>
          </section>
          <section className="section-element section-detail">
            <div className="left">
              <h3>รายละเอียดรถขนส่ง</h3>
              <p>ประเภทรถ : {info.truck_type}</p>
              <p>ทะเบียน : {info.truck_plate_number}</p>
            </div>
            {driverMobileNumberView(info.driver_mobile_number, storeContext?.storeDetail.number)}
          </section>
          <section className="section-element section-eta">
            <div className="left">
              <h3>สินค้าจะส่งถึงภายในวันที่</h3>
              {etaItem(info)}
            </div>
          </section>
          <section className="section-tracking">
            <h3>ตำแหน่งปัจจุบันของรถ</h3>
            <div className="wrap-google-map">
              <Map
                center={[
                  (info.lat && parseFloat(info.lat)) || defaultProps.center.lat,
                  (info.long && parseFloat(info.long)) || defaultProps.center.lng,
                ]}
                zoom={15}
                zoomSnap={false}
              >
                <ZoomControl />
                <Overlay anchor={[latStore, lngStore]}>
                  <div className="wrap-store-marker">
                    <img src={storeIcon} width={35} height={35} alt={`store-marker`} />
                  </div>
                </Overlay>
                <Overlay
                  anchor={[
                    (info.lat && parseFloat(info.lat)) || defaultProps.center.lat,
                    (info.long && parseFloat(info.long)) || defaultProps.center.lng,
                  ]}
                >
                  <img src={iconCar} width={35} height={35} alt="driver-marker" />
                </Overlay>
              </Map>
            </div>
            <p>*เป็นวันและเวลาจากการประเมินเท่านั้น อาจมีการคลาดเคลื่อนได้</p>
          </section>
        </div>
      )
    );
  };

  const successInfoView = (info: ShippingInfo) => {
    return (
      info && (
        <div className="section-transit-detail">
          <section className="section-element section-status">
            <div className="left">
              <h3>สถานะการจัดส่งสินค้า</h3>
            </div>
            <div className="right">
              <span className="complete">เสร็จสิ้น</span>
              <span>{GetDate(info.status_updated_at)}</span>
            </div>
          </section>
          <section className="section-element section-detail">
            <div className="left">
              <h3>รายละเอียดรถขนส่ง</h3>
              <p>ประเภทรถ : {info.truck_type}</p>
              <p>ทะเบียน : {info.truck_plate_number}</p>
            </div>
            {driverMobileNumberView(info.driver_mobile_number, storeContext?.storeDetail.number)}
          </section>
        </div>
      )
    );
  };

  const driverMobileNumberView = (driverMobileNumber: string, storeNumber?: string) => {
    if (driverMobileNumber === "") {
      return "";
    } else {
      return (
        <div className="right">
          <a
            href={`tel:${driverMobileNumber}`}
            onClick={() => {
              logEvent(analytics, "phone_call", {
                date: new Date(),
                store_number: storeNumber,
              });
            }}
          >
            <img src={iconPhone} alt="phone" />
            <span>ติดต่อรถขนส่ง</span>
          </a>
        </div>
      );
    }
  };

  const infoView = (info: ShippingInfo) => {
    if (info.status === "waiting" || info.status === "exception") {
      return waitingInfoView(info);
    } else if (info.status === "in-transit") {
      return inTransitInfoView(info);
    } else if (info.status === "success") {
      return successInfoView(info);
    } else {
      return <></>;
    }
  };

  return (
    <>
      {isLoading || isFetching ? (
        <LoadingComponent />
      ) : (
        <Layout className="wrap-content content-tracking">
          <section className="section-element section-today">
            <div className="left">
              <h3>คุณมีรายการรับสินค้าวันนี้</h3>
              <p>{updatedDateView()}</p>
            </div>
          </section>
          <div className="main-section-saperate">
            {shippingInfo && <Fragment>{infoView(shippingInfo)}</Fragment>}
          </div>
        </Layout>
      )}
    </>
  );
}

export default DetailComponent;
